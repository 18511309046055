@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

input::placeholder {
  text-align: center;
  font-size: 14px;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}

* {
  -webkit-tap-highlight-color: transparent;
  font-size: 24px;
  color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  transition: 500ms all ease;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav-top {
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 60px;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
}

.logo {
  text-decoration: none;
  margin-left: 12px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

#menu {
  padding: 10px 10px;
  margin-right: 2px;
  cursor: pointer;
  transform: rotate(0deg);
}

.Intro-Participate {
  vertical-align: middle;
  height: 200px;
  margin: 40px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  align-items: center;
  background: linear-gradient(to right, #134e5e, #71b280);
}

.Intro-ParticipateTitle1 {
  padding: 30px;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.Intro-ParticipateTitle2 {
  margin-right: 10px;
  padding: 5px 0px;
  color: white;
  font-weight: bold;
}

.main-second {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.Intro-Hosting {
}

.Intro-HostingTitle {
  line-height: 100px;
  color: white;
  font-weight: bold;
}
.Intro-Problem {
}

.Intro-ProblemTitle {
  line-height: 100px;
  color: white;
  font-weight: bold;
}

.Intro-ParticipateInput {
  border-radius: 5px;
  border: 2px solid;
}

.Intro-ParticipateButton {
  text-decoration: none;
  font-size: 26px;
  border-radius: 5px;
  opacity: 100%;
}
.Intro-ParticipateButton:hover {
  transform: scale(1.1);
}

.Participate {
  box-sizing: border-box;
}

.answerContainer {
  vertical-align: center;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.answer1,
.answer2,
.answer3,
.answer4 {
  cursor: pointer;
  line-height: 150px;
  color: white;
  font-weight: bold;
  height: 150px;
  width: 150px;
  margin: 10px 10px;
  display: inline-block;
}

.answer1 {
  background: linear-gradient(to right, #ec6f66, #f3a183);
}

.answer2 {
  background: linear-gradient(to right, #7474bf, #348ac7);
}

.answer3 {
  background: linear-gradient(to right, #134e5e, #71b280);
}

.answer4 {
  background: linear-gradient(
    to right,
    rgba(200, 120, 142),
    rgba(153, 43, 129)
  );
}

html {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}

section {
  border: 1px solid black;
  padding: 1rem;
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}
h2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: black;
  width: 100%;
  left: 0;
  font-size: calc(1rem + 3vw);
}

.modal {
  position: absolute;
  height: 170px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(200, 120, 142, 0.9),
    rgba(153, 43, 129, 0.9)
  );
  /* rgb(214, 46, 91), #ff9595 */
  /* #223c9f, #00d5ff */
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
  text-align: right;
  text-decoration: none;
}
